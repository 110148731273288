import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import CurrencyExchangeHelper from './CurrencyExchangeHelper';

export default function CurrencyExchange() {
  const [currencyRate, setCurrencyRate] = useState({});

  useEffect(() => {
    async function getCurrencyExchangeData() {
      const response = await fetch(
        'https://api.exchangeratesapi.io/latest?base=USD'
      );

      const result = await response.json();
      CurrencyExchangeHelper.filterAvailableCurrencyCode(result);
      setCurrencyRate(result);
    }
    getCurrencyExchangeData();
  }, []);

  return (
    <Card style={{ width: '24rem', boxShadow: '2px 2px' }}>
      <Card.Title className='text-center'>{currencyRate.base}</Card.Title>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Currency</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {currencyRate.rates !== undefined ? (
              Object.keys(currencyRate.rates).map(key => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{currencyRate.rates[key]}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>no data</td>
                <td />
              </tr>
            )}
            {/* if(currencyRate !== null && currencyRate.rates !== undefined)
            {Object.keys(currencyRate.rates).map((key) => (
              <tr>
                <td>{key}</td>
                <td>{currencyRate.rates[key]}</td>
              </tr>
            ))} */}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
