import React, { useState } from 'react';
import { Tabs, Tab, FormControl } from 'react-bootstrap';
import QRCode from 'qrcode';
import QRCodeScanner from './QRCodeScanner';

export default function CodeScanner() {
  const [imageUrl, setImageUrl] = useState('');
  const [qrCodeResult, setQrCodeResult] = useState('');
  const [isQrCodeReaderEnabled, setIsQrCodeReaderEnabled] = useState(false);

  const generateQrCode = async text => {
    try {
      const response = await QRCode.toDataURL(text);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };
  const changeTabs = async eventKey => {
    console.log(eventKey);
    if (eventKey !== 'qrCodeReader') {
      setIsQrCodeReaderEnabled(false);
    }
  };

  function updateQrCodeScanResult(val) {
    setQrCodeResult(val);
    setIsQrCodeReaderEnabled(false);
    console.log(isQrCodeReaderEnabled);
  }

  function updateIsEnabled(val) {
    setIsQrCodeReaderEnabled(val);
  }

  return (
    <div>
      <Tabs
        defaultActiveKey='qrCodeReader'
        id='uncontrolled-tab-example'
        transition={false}
        onSelect={eventKey => changeTabs(eventKey)}
      >
        <Tab eventKey='home' title='Generate QR Code'>
          <div>Generate QR Code</div>

          <FormControl
            placeholder='QR Code Text Here'
            aria-label='Text'
            onChange={e => generateQrCode(e.target.value)}
            as='textarea'
            rows={3}
          />

          {imageUrl ? (
            <a href={imageUrl} download>
              <img src={imageUrl} alt='img' />
            </a>
          ) : null}
        </Tab>
        <Tab eventKey='qrCodeReader' title='QR Code Reader'>
          <QRCodeScanner
            updateQrCodeScanResult={updateQrCodeScanResult}
            isScanEnabled={isQrCodeReaderEnabled}
            qrCodeResult={qrCodeResult}
            updateIsEnabled={updateIsEnabled}
          />
        </Tab>
        <Tab eventKey='contact' title='Contact' disabled>
          <div>Contact</div>
        </Tab>
      </Tabs>
    </div>
  );
}
