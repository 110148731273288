export default class CurrencyExchangeHelper {
  static getTargetedCurrencyCodes() {
    return [
      'CAD',
      'HKD',
      'USD',
      'GBP',
      'JPY',
      'CNY',
      'NZD',
      'SGD',
      'AUD',
      'KRW'
    ];
  }

  static filterAvailableCurrencyCode = result => {
    delete result.rates.BGN;
    delete result.rates.BRL;
    delete result.rates.CHF;
    delete result.rates.CZK;
    delete result.rates.DKK;
    delete result.rates.HRK;
    delete result.rates.HUF;
    delete result.rates.IDR;
    delete result.rates.ISK;
    delete result.rates.MXN;
    delete result.rates.MYR;
    delete result.rates.NOR;
    delete result.rates.ILS;
    delete result.rates.INR;
    delete result.rates.PHP;
    delete result.rates.PLN;
    delete result.rates.RON;
    delete result.rates.RUB;
    delete result.rates.SEK;
    delete result.rates.THB;
    delete result.rates.TRY;
    delete result.rates.ZAR;
    return result;
  };
}
