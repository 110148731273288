import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function QRCodeScanner(props) {
  const [result, setResult] = useState('scan');
  const [isEnabled, setIsEnabled] = useState(false);
  const { isScanEnabled, qrCodeResult } = props;
  useEffect(() => {
    setIsEnabled(isScanEnabled);
    setResult(qrCodeResult);
  }, [isScanEnabled, qrCodeResult]);

  function handleScan(data) {
    if (data) {
      setResult(data);
      props.updateQrCodeScanResult(data);
    }
  }

  function handleError(error) {
    console.error(error);
  }
  function btnUpdateEnabled(val) {
    props.updateIsEnabled(val);
  }

  return (
    <div>
      <Button
        type='button'
        onClick={() => btnUpdateEnabled(!isEnabled)}
        variant='success'
      >
        {isEnabled ? <span>Stop Scan</span> : <span>Scan</span>}
      </Button>
      {isEnabled ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '50%' }}
          facingMode='environment'
        />
      ) : null}
      {result ? (
        <div>
          <div>
            <b>Scan result:</b>
          </div>
          <div>{result}</div>
          <div>
            <CopyToClipboard text={result}>
              <Button type='button' variant='success'>
                Copy
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      ) : null}
    </div>
  );
}

QRCodeScanner.propTypes = {
  isScanEnabled: PropTypes.bool.isRequired,
  qrCodeResult: PropTypes.string.isRequired,
  updateQrCodeScanResult: PropTypes.func.isRequired,
  updateIsEnabled: PropTypes.func.isRequired
};
