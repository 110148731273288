import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

export default function NavBar() {
  return (
    <Navbar bg='light' expand='lg'>
      <Navbar.Brand href='/'>ShareChiWai</Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto'>
          <Nav.Link href='/'>Home</Nav.Link>
          <Nav.Link href='/geoipinfo'>Geo Ip</Nav.Link>
          <Nav.Link href='/weather'>Weather</Nav.Link>
          <Nav.Link href='/qrcode'>QRCode</Nav.Link>
          <Nav.Link href='/contact'>Contact</Nav.Link>
          <NavDropdown title='More' id='basic-nav-dropdown'>
            <NavDropdown.Item href='https://sharechiwai.com'>
              Blog
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href='https://sharechiwai.com'>
              Website
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        {/* <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
              />
              <Button variant="outline-success" onClick={() => alert("euah")}>
                Search
              </Button>
            </Form> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
