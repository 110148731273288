import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Contact from './pages/Contact/Contact';
import NavBar from './components/Layout/NavBar';
import CurrencyExchange from './components/CurrencyExchange/CurrencyExchange';
import Weather from './components/Weather/Weather';
import CodeScanner from './components/CodeScanner/CodeScanner';
import GeoIPInfo from './pages/GeoIPInfo';
import Home from './pages/Home/Home';
import Privacy from './pages/Home/Privacy';
import TermsAndConditions from './pages/Home/TermsAndConditions';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Container fluid='xl'>
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route exact path='/' component={Weather} />
          <Route exact path='/currency-exchange' component={CurrencyExchange} />
          <Route exact path='/geoipinfo' component={GeoIPInfo} />
          <Route exact path='/weather' component={Weather} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/home' component={Home} />
          <Route exact path='/qrcode' component={CodeScanner} />
          <Route
            exact
            path='/terms-and-conditions'
            component={TermsAndConditions}
          />
        </Switch>
      </BrowserRouter>
    </Container>
  );
}

export default App;
