import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

export default function Weather() {
  const [generalInfo, setGeneralInfo] = useState({});

  useEffect(() => {
    async function getWeatherGeneralInfo() {
      const response = await fetch(
        'https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=tc'
      );

      const result = await response.json();
      setGeneralInfo(result);
    }
    getWeatherGeneralInfo();
  }, []);

  return (
    <Card style={{ width: '36rem', boxShadow: '2px 2px', marginTop: '20px' }}>
      <Card.Title className='text-center'>Weather</Card.Title>
      <Card.Body>
        <p>{generalInfo.generalSituation}</p>
        <p>{generalInfo.forecastPeriod}</p>
        <p>{generalInfo.forecastDesc}</p>
        <p>{generalInfo.outlook}</p>
        <p>{generalInfo.updateTime}</p>
      </Card.Body>
    </Card>
  );
}
